<template>
  <v-row class="match-height">
    <v-col cols="12" md="12">
      <v-card>
        <v-data-table
          dense
          :headers="headers"
          :items="desserts"
          :loading="loading"
          hide-default-footer
          :page.sync="page"
          :items-per-page="table.itemsPerPage"
          class="elevation-1"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>Daftar Pinjaman</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-dialog v-model="dialog" max-width="900px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on" @click="resetForm"> Tambah </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="text-h5">{{ formTitle }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="1" sm="1" md="2">
                          <v-btn color="primary" small @click="dialogPilihanggota = true"> Pilih Anggota </v-btn>
                          <input type="hidden" v-model="editedItem.id_anggota" />
                        </v-col>
                        <v-col cols="12" sm="6" md="3">
                          <v-text-field
                            readonly
                            v-model="editedItem.kode_anggota"
                            label="Kode Anggota *"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field
                            readonly
                            v-model="editedItem.nama_anggota"
                            label="Nama Anggota *"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="3">
                          <v-text-field
                            readonly
                            v-model="editedItem.jenis_upah_anggota"
                            label="Jenis Upah *"
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" sm="6" md="4">
                          <label for="">Program Pinjaman</label>
                          <v-autocomplete
                            v-model="editedItem.id_jenis_pinjaman"
                            :items="programpinjamans"
                            dense
                            item-text="text"
                            item-value="value"
                            outlined
                            @change="programPinjamanChange()"
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="6" md="5">
                          <v-row>
                            <v-col cols="6">
                              <v-text-field
                                outlined
                                v-model="editedItem.besar_pinjam"
                                label="Besar Pinjam *"
                                hide-details
                              ></v-text-field>
                            </v-col>
                            <v-col cols="6" style="font-weight: bold; padding-top: 30px; color: red">
                              {{ new Intl.NumberFormat(['id']).format(editedItem.besar_pinjam) }}
                            </v-col>
                          </v-row>
                        </v-col>
                        <v-col cols="12" sm="6" md="3">
                          <v-text-field
                            v-model="editedItem.lama_angsuran"
                            outlined
                            hide-details
                            label="Lama Angsuran *"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field
                            readonly
                            v-model="editedItem.persen_bunga"
                            outlined
                            label="Bunga (%) *"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field
                            v-model="editedItem.tgl_pinjaman"
                            type="date"
                            label="Tgl Pinjaman"
                            dense
                            outlined
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="secondary" @click="close"> Batal </v-btn>
                    <v-btn color="primary" :loading="progerty.save_loading" @click="save"> Simpan </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog v-model="dialogPilihanggota" max-width="900px">
                <v-card>
                  <v-card-title>
                    <span class="text-h5">Pilih Anggota</span>
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                      <pilih-anggota></pilih-anggota>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="secondary" @click="dialogPilihanggota = false"> Batal </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <!-- dialog delete -->
              <v-dialog persistent v-model="dialogDelete" max-width="350">
                <v-card>
                  <v-card-title class="headline">Konfirmasi</v-card-title>
                  <v-card-text>
                    <h3 class="mb-4">Apakah yakin akan menghapus?</h3>
                    <v-text-field
                      v-model="pin_super"
                      type="password"
                      outlined
                      hide-details=""
                      dense
                      label="Masukkan pin:"
                    ></v-text-field>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="secondary" @click="closeDelete()">Batal</v-btn>
                    <v-btn color="error" @click="commitDeleteItem()">Hapus</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>

            <v-form class="ml-4">
              <v-row>
                <v-col cols="2" md="2">
                  <label for="firstname">Status</label>
                </v-col>
                <v-col cols="3" md="3">
                  <v-select
                    :items="status"
                    hide-details="auto"
                    outlined
                    dense
                    v-model="table.status"
                    @change="optionChange()"
                  ></v-select>
                </v-col>
                <v-col cols="2" md="2">
                  <label for="firstname">Tanggal Tempo Mulai</label>
                </v-col>
                <v-col cols="3" md="3">
                  <v-text-field
                    v-model="table.tanggal_mulai"
                    type="date"
                    dense
                    outlined
                    hide-details
                    @change="optionChange()"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="2" md="2">
                  <label for="firstname">Sisa Angsuran</label>
                </v-col>
                <v-col cols="3" md="3">
                  <v-select
                    :items="sisa_angsuran"
                    hide-details="auto"
                    outlined
                    dense
                    v-model="table.sisa_angsuran"
                    @change="optionChange()"
                  ></v-select>
                </v-col>
                <v-col cols="2" md="2">
                  <label for="firstname">Tanggal Tempo Akhir</label>
                </v-col>
                <v-col cols="3" md="3">
                  <v-text-field
                    v-model="table.tanggal_akhir"
                    type="date"
                    dense
                    outlined
                    hide-details
                    @change="optionChange()"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="2" md="2">
                  <label for="firstname">Jenis Upah</label>
                </v-col>
                <v-col cols="3" md="3">
                  <v-select
                    :items="jenis_upahs"
                    outlined
                    dense
                    v-model="table.jenis_upah"
                    hide-details
                    @change="optionChange()"
                  ></v-select>
                </v-col>
                <v-col cols="2" md="2">
                  <label for="firstname">Pencarian</label>
                </v-col>
                <v-col cols="3" md="3">
                  <v-text-field
                    outlined
                    dense
                    placeholder="ID/ Nama/ Kode anggota"
                    hide-details
                    v-model="table.search"
                    @change="optionChange()"
                  ></v-text-field>
                </v-col>
                <v-col cols="1" md="1">
                  <v-btn class="primary" small @click="optionChange()">Lihat</v-btn>
                </v-col>
              </v-row>
            </v-form>
          </template>

          <template v-slot:item.no="{ item, index }">
            {{ index + 1 }}
          </template>
          <template v-slot:item.anggota="{ item }">
            {{ item.nama_anggota + ' (' + item.kode_anggota + ')' }}
          </template>
          <template v-slot:item.jenis_pinjaman="{ item }">
            {{ item.jenis_pinjaman }}
          </template>
          <template v-slot:item.besar_pinjam="{ item }">
            <div style="text-align: right">{{ new Intl.NumberFormat(['id']).format(item.besar_pinjam) }}</div>
          </template>
          <template v-slot:item.angsuran="{ item }">
            {{
              item.x_angsur +
                ' ' +
                (item.jenis_upah != null ? item.jenis_upah.replace('AN', '') : '') +
                ' Dari ' +
                item.lama_angsuran +
                ' ' +
                (item.jenis_upah != null ? item.jenis_upah.replace('AN', '') : '')
            }}
          </template>
          <template v-slot:item.besar_angsuran="{ item }">
            {{ new Intl.NumberFormat(['id']).format(parseInt(item.besar_angsuran) + parseInt(item.bunga)) }}
          </template>

          <template v-slot:item.tgl_tempo="{ item }">
            {{ formatDate(item.tgl_tempo) }}
          </template>

          <template v-slot:item.aksi="{ item }">
            <div style="display: flex">
              <router-link
                v-if="item.status == 'BELUM LUNAS'"
                :to="{ name: 'angsuran', params: { id_pinjaman: item.id } }"
              >
                <v-btn x-small color="primary">Angsur</v-btn>
              </router-link>
              <router-link v-else :to="{ name: 'angsuran', params: { id_pinjaman: item.id } }">
                <v-btn x-small color="primary">Angsur</v-btn>
              </router-link>
              <v-btn x-small v-if="user.is_superadmin == true" color="error" class="ml-1" @click="deleteItem(item)"
                >Hapus</v-btn
              >
            </div>
          </template>

          <template v-slot:no-data>
            <v-btn color="primary" small @click="getDataFromApi"> Reload</v-btn>
          </template>
        </v-data-table>
        <div class="text-center px-4 py-4">
          <v-row>
            <v-col cols="3">
              <v-select
                label="Jumlah Per Halaman"
                :items="length_menus"
                hide-details="auto"
                outlined
                dense
                v-model="table.itemsPerPage"
                @change="optionChange()"
              ></v-select>
            </v-col>
            <v-col cols="9">
              <v-pagination v-model="page" :length="table.pageCount" :total-visible="7"></v-pagination>
            </v-col>
          </v-row>
        </div>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import axios from 'axios'
import { mdiEye } from '@mdi/js'
import PilihAnggota from './pinjaman/PilihAnggota.vue'
import { axiosPostAuth, axiosGetAuth } from '@/utils/axios'

const apiRoot = process.env.VUE_APP_APIURL
export default {
  components: {
    PilihAnggota,
  },
  setup() {
    return {
      icons: {
        mdiEye,
      },
    }
  },
  data: () => ({
    progerty: {
      save_loading: false,
    },
    // datatable

    dialog: false,
    headers: [
      { text: 'No', value: 'no', sortable: false },
      { text: 'ID', value: 'id', sortable: false },
      { text: 'Anggota', value: 'anggota', sortable: false },
      { text: 'Program', value: 'jenis_pinjaman', sortable: false },
      { text: 'Besar Pinjam', value: 'besar_pinjam', sortable: false },
      { text: 'Angsuran', value: 'angsuran', sortable: false },
      { text: 'Besar Angsur', value: 'besar_angsuran', sortable: false },
      { text: 'Tgl Tempo', value: 'tgl_tempo', sortable: false },
      { text: 'Status', value: 'status', sortable: false },
      { text: 'Aksi', value: 'aksi', sortable: false },
    ],
    desserts: [],
    loading: false,
    page: 1,
    table: {
      pageCount: 0,
      itemsPerPage: 10,
      search: '',
      status: 'SEMUA',
      sisa_angsuran: 'SEMUA',
      jenis_upah: 'SEMUA',
      tanggal_mulai:
        new Date()
          .toJSON()
          .slice(0, 8)
          .replace(/-/g, '-') + '01',
      tanggal_akhir:
        new Date()
          .toJSON()
          .slice(0, 4)
          .replace(/-/g, '-') + '-12-31',
      totalBesarSimpanan: 0,
    },
    length_menus: [10, 25, 50, 100],
    jenis_upahs: ['SEMUA', 'HARIAN', 'MINGGUAN', 'BULANAN'],
    status: ['BELUM LUNAS', 'LUNAS', 'SEMUA'],
    sisa_angsuran: ['SEMUA', 1, 2, 3, 4, 5],

    editedIndex: -1,
    editedItem: {
      id_anggota: '',
      nama_anggota: '',
      kode_anggota: '',
      jenis_upah_anggota: '',
      id_jenis_pinjaman: '',
      besar_pinjam: 0,
      lama_angsuran: 0,
      besar_angsuran: 0,
      persen_bunga: 0,
      bunga: 0,
      tgl_pinjaman: new Date()
        .toJSON()
        .slice(0, 10)
        .replace(/-/g, '-'),
    },
    defaultItem: {
      id: '',
      nama: '',
      maks_pinjam: '',
      lama_angsuran: '',
      bunga: '',
      jenis_upah: '',
      aktif: '',
    },
    programpinjamans: [],
    search: '',
    dialogPilihanggota: false,
    dialogDelete: false,
    pin_super: '',
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'Tambah Pinjaman' : 'Detail Pinjaman'
    },
    token() {
      return this.$store.getters.token
    },
    anggotaSelected() {
      return this.$store.getters.anggotaSelected
    },
    user() {
      return this.$store.getters.user
    },
  },
  watch: {
    page: {
      handler() {
        this.getDataFromApi()
      },
      deep: true,
    },
    anggotaSelected() {
      this.editedItem.id_anggota = this.anggotaSelected.id
      this.editedItem.kode_anggota = this.anggotaSelected.kode_anggota
      this.editedItem.nama_anggota = this.anggotaSelected.nama
      this.editedItem.jenis_upah_anggota = this.anggotaSelected.jenis_upah
      this.dialogPilihanggota = false
      this.getProgrampinjamans()
    },
  },
  created() {
    this.getDataFromApi()
  },

  methods: {
    getDataFromApi() {
      this.loading = true

      // const { page, itemsPerPage } = this.options

      const headers = {
        Authorization: this.token,
      }
      const form = new FormData()
      form.append('page', this.page)
      form.append('itemsPerPage', this.table.itemsPerPage)
      form.append('search', this.table.search)
      form.append('status', this.table.status)
      form.append('sisa_angsuran', this.table.sisa_angsuran)
      form.append('jenis_upah', this.table.jenis_upah)
      form.append('tanggal_mulai', this.table.tanggal_mulai)
      form.append('tanggal_akhir', this.table.tanggal_akhir)
      axios
        .post(`${apiRoot}/api/Pinjaman/getTable`, form, { headers })
        .then(response => {
          if (response.data.code === 401) {
            this.$store.dispatch('logout', 'Session berakhir!')
          } else if (response.data.code === 200) {
            this.desserts = response.data.data
            this.table.pageCount = response.data.length
          } else {
            alert(response.data.message)
          }
          this.loading = false
        })
        .catch(error => {
          alert(error)
          this.loading = false
        })
    },
    getProgrampinjamans() {
      if (this.editedItem.jenis_upah_anggota !== undefined) {
        this.$store.dispatch('loading', true)

        const headers = {
          Authorization: this.token,
        }
        axios
          .get(`${apiRoot}/api/JenisPinjaman/getByJenisupah/${this.editedItem.jenis_upah_anggota}`, { headers })
          .then(response => {
            if (response.data.code === 401) {
              this.$store.dispatch('logout', 'Session berakhir!')
            } else if (response.data.code === 200) {
              this.programpinjamans = []
              response.data.data.forEach(element => {
                this.programpinjamans.push({
                  text: element.nama,
                  value: element.id,
                  lama_angsuran: element.lama_angsuran,
                  maks_pinjam: element.maks_pinjam,
                  bunga: element.bunga,
                })
              })
            } else {
              alert(response.data.message)
            }
            this.$store.dispatch('loading', false)
          })
          .catch(error => {
            alert(error)
            this.$store.dispatch('loading', false)
          })
      }
    },
    programPinjamanChange() {
      this.programpinjamans.forEach(element => {
        if (this.editedItem.id_jenis_pinjaman.value === element.id) {
          this.editedItem.lama_angsuran = element.lama_angsuran
          this.editedItem.besar_pinjam = element.maks_pinjam
          this.editedItem.persen_bunga = element.bunga
        }
      })
    },
    hitungAngsuran() {
      let angsuran = parseInt(this.editedItem.besar_pinjam) / parseInt(this.editedItem.lama_angsuran)
      this.editedItem.besar_angsuran = angsuran
    },
    editItem(item) {
      this.editedIndex = this.desserts.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.editedItem.jenis_upah = { text: item.jenis_upah, value: item.jenis_upah }
      this.dialog = true
    },

    deleteItem(item) {
      this.editedIndex = this.desserts.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.pin_super = ''
      this.dialogDelete = true
    },
    commitDeleteItem() {
      const fmData = new FormData()
      fmData.append('id_pinjaman', this.editedItem.id)
      fmData.append('pin', this.pin_super)
      axiosPostAuth('api/Pinjaman/hapus', fmData)
        .then(response => {
          if (response.code === 200) {
            this.getDataFromApi()
            this.closeDelete()
          } else {
            alert(response.message)
          }
        })
        .catch(error => {
          alert(error)
        })
    },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    save() {
      if (this.editedIndex > -1) {
        // Object.assign(this.desserts[this.editedIndex], this.editedItem)

        const headers = {
          Authorization: this.token,
        }
        const form = new FormData()
        form.append('id', this.editedItem.id)
        form.append(
          'tgl_acc',
          new Date()
            .toJSON()
            .slice(0, 10)
            .replace(/-/g, '-'),
        )
        form.append('besar_pinjam_acc', this.editedItem.besar_pinjam_acc)
        form.append('jangka_waktu_acc', this.editedItem.jangka_waktu_acc)
        axios
          .post(`${apiRoot}/api/Pengajuan/updatePengajuan`, form, { headers })
          .then(response => {
            if (response.data.code === 401) {
              this.$store.dispatch('logout', 'Session berakhir!')
            } else if (response.data.code === 200) {
              // this.search = ''
              // this.options.page = 1
              this.getDataFromApi()
              this.close()
            } else {
              alert(response.data.message)
            }
          })
          .catch(error => {
            alert(error)
          })
      } else {
        this.progerty.save_loading = true

        const headers = {
          Authorization: this.token,
        }
        const form = new FormData()
        form.append('id_anggota', this.editedItem.id_anggota)
        form.append('id_jenis_pinjaman', this.editedItem.id_jenis_pinjaman)
        form.append('besar_pinjam', this.editedItem.besar_pinjam)
        form.append('lama_angsuran', this.editedItem.lama_angsuran)
        form.append('persen_bunga', this.editedItem.persen_bunga)
        form.append('jenis_upah', this.editedItem.jenis_upah_anggota)
        form.append('tgl_pinjaman', this.editedItem.tgl_pinjaman)
        axios
          .post(`${apiRoot}/api/Pinjaman/add`, form, { headers })
          .then(response => {
            if (response.data.code === 401) {
              this.$store.dispatch('logout', 'Session berakhir!')
            } else if (response.data.code === 200) {
              // this.search = ''
              // this.options.page = 1
              // this.getDataFromApi()
              this.optionChange()
              this.close()
            } else {
              alert(response.data.message)
            }
            this.progerty.save_loading = false
          })
          .catch(error => {
            alert(error)
            this.progerty.save_loading = false
          })
      }
    },
    angsur() {},
    resetForm() {
      this.$store.state.anggotaSelected = {}
    },
    optionChange() {
      if (this.table.tanggal_mulai !== '' && this.table.tanggal_akhir !== '') {
        this.page = 1
        this.getDataFromApi()
      }
    },
  },
}
</script>
