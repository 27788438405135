<template>
  <v-row class="match-height">
    <v-col cols="12" md="12">
      <v-data-table
        :headers="headers"
        :items="desserts"
        :options.sync="options"
        :server-items-length="totalDesserts"
        :loading="loading"
        dense
        class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>Anggota</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-text-field
              v-model="search"
              @keyup.enter="searching()"
              append-icon="mdi-magnify"
              label="Kode / nama anggota"
              single-line
              hide-details
            ></v-text-field>
            <v-btn class="primary" @click="searching()"> Cari </v-btn>
          </v-toolbar>
        </template>
        <template v-slot:item.tgl_lahir="{ item }">
          {{ formatDate(item.tgl_lahir) }}
        </template>
        <template v-slot:item.jumlah_upah="{ item }">
          {{ new Intl.NumberFormat(['id']).format(item.jumlah_upah) }}
        </template>
        <template v-slot:item.aksi="{ item }">
          <v-btn class="secondary" small @click="pilihItem(item)" title="Pilih">
            <v-icon small>
              {{ icons.mdiArrowRightBoldBox }}
            </v-icon>
          </v-btn>
        </template>
        <template v-slot:no-data>
          <v-btn color="primary" small @click="getDataFromApi"> Reload</v-btn>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import axios from 'axios'
import { mdiArrowRightBoldBox } from '@mdi/js'

const apiRoot = process.env.VUE_APP_APIURL
export default {
  data: () => ({
    icons: {
      mdiArrowRightBoldBox,
    },
    dialog: false,
    dialogDelete: false,
    headers: [
      { text: 'No', value: 'no', sortable: false },
      { text: 'Kode Anggota', value: 'kode_anggota', sortable: false },
      { text: 'Nama', value: 'nama', sortable: false },
      { text: 'Tgl Lahir', value: 'tgl_lahir', sortable: false },
      { text: 'Bagian', value: 'divisi', sortable: false },
      { text: 'Wilayah', value: 'wilayah', sortable: false },
      { text: 'Jns Upah', value: 'jenis_upah', sortable: false },
      { text: 'Jumlah Upah', value: 'jumlah_upah', sortable: false, align: 'right' },
      { text: 'Aksi', value: 'aksi', sortable: false },
    ],
    totalDesserts: 0,
    desserts: [],
    loading: true,
    options: {},
    search: '',
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'Tambah Anggota' : 'Edit Anggota'
    },
    token() {
      return this.$store.getters.token
    },
  },

  watch: {
    options: {
      handler() {
        this.getDataFromApi()
      },
      deep: true,
    },
  },

  created() {
    // this.initialize()
  },

  methods: {
    // initialize() {
    // },
    getDataFromApi() {
      this.loading = true

      // this.fakeApiCall().then(data => {
      //   this.desserts = data.items
      //   this.totalDesserts = data.total
      //   this.loading = false
      // })

      const { page, itemsPerPage } = this.options

      // console.log(sortBy.length + ' ' + sortDesc.length + ' ' + page + ' ' + itemsPerPage)
      // 0 0 1 10

      const headers = {
        Authorization: this.token,
      }
      const form = new FormData()
      form.append('search', this.search)
      form.append('page', page)
      form.append('itemsPerPage', itemsPerPage)
      axios
        .post(`${apiRoot}/api/Anggota/getTable`, form, { headers })
        .then(response => {
          if (response.data.code === 401) {
            this.$store.dispatch('logout', 'Session berakhir!')
          } else if (response.data.code === 200) {
            this.desserts = response.data.data
            this.totalDesserts = response.data.total
          } else {
            alert(response.data.message)
          }
          this.loading = false
        })
        .catch(error => {
          alert(error)
          this.loading = false
        })
    },
    searching() {
      this.options.page = 1
      this.getDataFromApi()
    },
    pilihItem(item) {
      this.$store.state.anggotaSelected = item
    },
  },
}
</script>
